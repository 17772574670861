import React from 'react';
import Link from 'next/link';

const NoRecommendation = () => {
  return <div className="card-body py-0">
    <div className="p-5 text-white bg-dark my-1 rounded">
      <p>Belum ada rekomendasi.</p>
      <p>Lakukan pencarian dan mulai dapatkan rekomendasi.</p>
      <Link href="/listing">
        <a className="btn btn-primary rounded-pill">Lakukan Pencarian</a>
      </Link>
    </div>
  </div>
}

export default NoRecommendation;