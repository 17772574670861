import { gql } from "@apollo/client";
import { initializeApollo } from "../apollo-client";

export const getReel = async function (distinctId) {
    const client = initializeApollo();
    return await client.query({
        query: gql`
            query GetReels {
                reels_query{
                    reel(distinct_id:"${distinctId}"){
                        distinct_id
                        latest_preferences
                    }
                }
            }
        `,
    })
};