import React, { useEffect, useState } from "react";
import PackageCard from "./PackageCard";
import { useLazyQuery } from "@apollo/client";
import { PACKAGE_PRICES } from "../../action/packages";
import { useStateContext } from "../../context";
import { useCookie } from "next-cookie";

const PackageSection = () => {
  const [getPackagePrice, { loading, error, data }] =
    useLazyQuery(PACKAGE_PRICES);

  const cookie = useCookie();
  const lat = cookie.get("lat");
  const lng = cookie.get("lng");

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    getPackagePrice({
      variables: {
        types: isChecked ? "Yearly" : "Monthly",
        lat: lat || null,
        lng: lng || null,
      },
    });
  }, [isChecked, lat, lng]);

  const dataPackage = error ? [] : data?.package_prices_for_user;

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div
      id="packages"
      className="container text-center d-flex flex-column gap-4"
    >
      <div>
        <p className="h1 text-primary fw-bolder">Harga Spesial</p>
        <p className="text-secondary h5">
          Pilih Paket Yang Sesuai Dengan Kebutuhan Anda
        </p>
      </div>

      <div className="mx-auto d-flex gap-2 align-items-center">
        <p className={`${!isChecked ? "text-primary" : "text-secondary"} mb-0`}>
          6 Bulan
        </p>

        <label className="custom-capsule-switch">
          <input type="checkbox" checked={isChecked} onChange={handleToggle} />
          <span className="slider"></span>
        </label>

        <p className={`${isChecked ? "text-primary" : "text-secondary"} mb-0`}>
          Tahun
        </p>
      </div>

      <div className={`row gy-5 py-4`}>
        {loading
          ? Array(3)
              .fill("_")
              .map((pkg, i) => (
                <div key={i} className={`col-6 col-md-4`}>
                  <div
                    className="package-card placeholder"
                    style={{ height: "200px" }}
                  ></div>
                </div>
              ))
          : dataPackage?.map((pkg, i) => (
              <div key={i} className={`col-12 col-md-4 mb-5 mb-md-0`}>
                <PackageCard
                  color={(i - 1) % 3 === 0 ? "primary" : "secondary"}
                  loading={loading}
                  data={pkg}
                />
              </div>
            ))}
      </div>
    </div>
  );
};

export default PackageSection;
