import React from "react";
import ContentLoader from "react-content-loader";

const ListingItemLoading = () => {
  let rows = [];
  for (let i = 0; i < 6; i++) {
    rows.push(
      <div key={i} className="col-6 col-sm-4 mb-3">
        <ContentLoader
          speed={2}
          viewBox="0 0 100 100"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          style={{ width: "100%" }}
          uniqueKey={"listingItemLoading"}
        >
          <rect x="0" y="0" rx="0" ry="0" width="100" height="300" />
        </ContentLoader>
      </div>
    );
  }
  return <div className="row">{rows}</div>;
};

export default ListingItemLoading;
